<template>
  <div class="common-file">
    <div class="banner">
      <img src="../../assets/images/commonfile-banner.png" alt />
    </div>
    <div class="container">
      <div class="content">
        <h1 class="content-title">伊莉丝钻石隐私保护政策</h1>
        <p>塞维利亚（北京）文化科技有限公司尊重并保护所有定制产品和服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本公司会按照本隐私权保护政策的规定使用您的个人信息。但本公司将以高度的勤勉、审慎义务对待这些信息。除本隐私权保护政策另有规定外，在未征得您事先许可的情况下，本公司不会将这些信息对外披露或向第三方提供。本公司可能会不定期更新本隐私权保护政策。您在同意伊莉丝私人定制服务协议之时，即视为您已经同意本隐私权保护政策全部内容。本隐私权保护政策属于伊莉丝私人定制服务协议不可分割的一部分。</p>
        <h2>一，适用范围</h2>
        <p>
          (a)
          由于本公司提供的产品和服务是私人定制的钻石珠宝类产品，因此在您购买之前需要您提供包括但不限于购买者姓名，通讯地址，电话，电子邮箱，肖像照片以及头发样本等产品定制所需的材料及信息；
        </p>
        <p>(b) 在注册伊莉丝官网帐号时，根据本公司要求提供的个人注册信息；</p>
        <p>
          (c)
          在访问伊莉丝官网平台网站时，本公司自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
        </p>
        <p>(d) 本公司通过合法途径从商业伙伴处取得的用户个人数据。</p>
        <h2>二，信息使用</h2>
        <p>(a)本公司采集的绝大部分客户信息，是用于提供专属的、个性化的产品和服务，旨在为客户营造更好的客户体验和产品质感。</p>
        <p>(b)本公司不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本公司（含本公司关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
        <p>
          (c)
          本公司亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本公司员工如从事上述活动，一经发现，本公司有权立即终止与其签署的劳动协议。
        </p>
        <p>
          (d)
          为服务客户的目的，本公司可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本公司合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
        </p>
        <h2>三，信息披露</h2>
        <p>在如下情况下，本公司将依据您的个人意愿或法律的规定全部或部分披露您的个人信息：</p>
        <p>(a) 经您事先同意，向第三方披露；</p>
        <p>(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
        <p>
          (c)
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        </p>
        <p>
          (d)
          如您出现违反中国有关法律、法规或者本服务协议或相关规则的情况，需要向第三方披露；
        </p>
        <p>
          (e)
          如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
        </p>
        <p>(f) 其它本公司根据法律、法规或者网站政策认为合适的披露。</p>
        <h2>四，信息安全</h2>
        <p>
          (a)
          本公司官网帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本公司官网将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
        </p>
        <p>
          (b)
          本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本人用户名及密码发生泄露，请您立即联络本公司客服，以便本公司采取相应措施。
        </p>
        <p>以上隐私权保护政策的一切解释权归塞维利亚（北京）文化科技有限公司所有。</p>
        <footer>塞维利亚（北京）文化科技有限公司</footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonFile",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style lang="less" scoped>
.common-file {
  .banner {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    width: 1000px;
    margin: 60px auto 200px;
    .title {
      font-size: 34px;
      line-height: 1;
      margin-bottom: 30px;
      color: #333;
      text-align: center;
    }
    .sub-title {
      margin-bottom: 44px;
      font-size: 18px;
      color: #999;
      text-align: center;
    }
    .label {
      width: 100%;
      padding: 30px 160px 28px;
      text-align: center;
      color: #666;
      border: 1px solid #cfcfcf; /*no*/
      border-bottom-width: 4px; /*no*/
      margin-bottom: 66px;
    }
    .content-title {
      font-size: 24px;
      color: #333;
      margin-bottom: 40px;
      text-align: center;
    }
    .content {
      h2 {
        margin-top: 30px;
      }
      p {
        font-size: 16px;
        line-height: 1.8;
        color: #666;
        margin-top: 10px;
        text-indent: 2em;
      }
      footer {
        margin-top: 30px;
        text-align: right;
      }
    }
  }
}
</style>
